import React, { FC, useState, useEffect, useMemo } from 'react';
import cn from 'classnames';
import { GreenBoxButton } from 'src/components';
import { PlatformsIDs } from 'src/constants/globalConstants';
import { Platform, CriteriaNames } from '../../model';
import { CRITERIA_OPTIONS } from '../../constants';
import { CriteriaSelection } from './components';
import styles from './CriteriaManagePanel.module.scss';

type Props = {
  isSME: boolean;
  isViewPage: boolean;
  selectedID: PlatformsIDs;
  selectedPlatform: Platform;
  onApplyCriterias: (selectedID: PlatformsIDs, criteriaSelected: string[]) => void;
  onSetInvestedPlatforms: React.Dispatch<React.SetStateAction<Platform[]>>;
  wrapperClass?: string;
};

const CriteriaManagePanel: FC<Props> = ({
  isSME,
  isViewPage,
  selectedID,
  selectedPlatform,
  onApplyCriterias,
  onSetInvestedPlatforms,
  wrapperClass
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!isSME) {
      onSetInvestedPlatforms((prev) =>
        prev.map(({ criteriaSelected, ...item }) => ({
          ...item,
          criteriaSelected: criteriaSelected.filter((c) => c !== CriteriaNames.SME_SECTORS)
        }))
      );
    }
  }, [isSME, onSetInvestedPlatforms]);

  const criteriaOptions = useMemo(
    () => CRITERIA_OPTIONS.filter(({ id }) => !(!isSME && id === CriteriaNames.SME_SECTORS)),
    [isSME]
  );

  return (
    <div className={cn(styles.wrapper, { [wrapperClass]: wrapperClass })}>
      <div className={cn(styles.buttonBox, { [styles.buttonBoxActive]: selectedPlatform && selectedID })}>
        <GreenBoxButton btnClass={styles.button} onClick={() => setOpen((p) => !p)} disabled={!selectedID}>
          {getBtnTitle(isViewPage, selectedPlatform?.label)}
        </GreenBoxButton>

        <CriteriaSelection
          open={open}
          isViewPage={isViewPage}
          criteriaOptions={criteriaOptions}
          criteriaSelected={selectedPlatform?.criteriaSelected ?? []}
          onApply={(criteriaSelected) => onApplyCriterias(selectedID, criteriaSelected)}
          onClose={() => setOpen(false)}
        />
      </div>
    </div>
  );
};

const getBtnTitle = (isViewPage: boolean, name: string) => {
  return name
    ? `${isViewPage ? 'View' : 'Manage'} investment criteria - ${name}`
    : `Select a platform to ${isViewPage ? 'view' : 'customize'} your investment strategy`;
};

export default CriteriaManagePanel;
