import React, { FC, useMemo, useCallback } from 'react';
import cn from 'classnames';
import { ShouldRender } from 'src/components';
import { GeneralConstraints } from '..';
import { CriteriaNames, Platform } from '../../model';
import useCriteriaColumnLayout from './useCriteriaColumnLayout';
import styles from './CriteriaFilters.module.scss';

type Props = {
  isSME: boolean;
  isViewPage: boolean;
  selectedPlatform: Platform;
  investmentCurrency: string;
  onInvestmentChange: (value: number) => void;
  onGeneralRangeChange: (id, value: number[]) => void;
  onCriteriaChange: (criteriaName: CriteriaNames, id: string | number, percentage: number) => void;
  wrapperClass?: string;
};

const CriteriaFilters: FC<Props> = ({
  isSME,
  isViewPage,
  selectedPlatform,
  investmentCurrency,
  onInvestmentChange,
  onGeneralRangeChange,
  onCriteriaChange,
  wrapperClass
}) => {
  const hasCriteria = useCallback(
    (name: CriteriaNames) => !!selectedPlatform?.criteriaSelected?.includes(name),
    [selectedPlatform?.criteriaSelected]
  );

  const layoutCriteriaNames = useMemo(
    () =>
      selectedPlatform?.criteriaSelected
        ? selectedPlatform?.criteriaSelected.filter((c) => c !== CriteriaNames.GENERAL)
        : [],
    [selectedPlatform?.criteriaSelected]
  ) as CriteriaNames[];

  const { columnLeft, columnRight } = useCriteriaColumnLayout(
    layoutCriteriaNames,
    selectedPlatform,
    onCriteriaChange,
    isViewPage,
    isSME
  );

  return (
    <div className={cn(styles.wrapper, { [wrapperClass]: wrapperClass })}>
      <ShouldRender should={hasCriteria(CriteriaNames.GENERAL)}>
        <GeneralConstraints
          isViewPage={isViewPage}
          investmentPercentage={selectedPlatform?.investmentPercentage}
          onInvestmentChange={onInvestmentChange}
          investmentCurrency={investmentCurrency}
          rangeFilters={selectedPlatform?.general}
          onRangeChange={onGeneralRangeChange}
        />
      </ShouldRender>

      <div className={styles.criteriaGrid}>
        <div className={styles.criteriaColumn}>
          {columnLeft.map(({ criteriaName, criteriaComponent }) => (
            <ShouldRender key={criteriaName} should={hasCriteria(criteriaName)}>
              {criteriaComponent}
            </ShouldRender>
          ))}
        </div>

        <div className={styles.criteriaColumn}>
          {columnRight.map(({ criteriaName, criteriaComponent }) => (
            <ShouldRender key={criteriaName} should={hasCriteria(criteriaName)}>
              {criteriaComponent}
            </ShouldRender>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CriteriaFilters;
