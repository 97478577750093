import React, { SVGProps } from 'react';
import {
  HomePage,
  DashboardPage,
  InvestmentsPage,
  OpportunitiesPage,
  PortfolioBuilderPage,
  PlatformAnalyticsPage,
  StatisticsPage,
  HelpCenterPage,
  Error404Page,
  ServerErrorPage,
  LoginPage,
  RegisterPage,
  MyProfilePage,
  SettingsAndPrivacyPage,
  PasswordRecoveryPage,
  ConfirmPasswordRecoveryPage,
  ChangePasswordSuccessPage,
  AccountVerifiedPage,
  ApiSettingsPage,
  UpdatePasswordPage,
  MarketplacePage,
  MarketplaceGlobal,
  EntityDetailsPage,
  ReportsPage
} from '../pages';
import { PAGES } from './pages';

import { ReactComponent as DashboardIcon } from 'src/assets/images/icons/aside_menu/menu_dashboard.svg';
import { ReactComponent as InvestmentsIcon } from 'src/assets/images/icons/aside_menu/menu_investments.svg';
import { ReactComponent as BuilderIcon } from 'src/assets/images/icons/aside_menu/menu_builder.svg';
import { ReactComponent as StatisticsIcon } from 'src/assets/images/icons/aside_menu/menu_reports.svg';
import { ReactComponent as MarketplaceIcon } from 'src/assets/images/icons/aside_menu/menu_marketplace.svg';
import { OpportunitiesSidebarIcon as OpportunitiesIcon, ReportsSidebarIcon as ReportsIcon } from 'src/atoms';

export interface AsideMenuItem {
  title: string;
  icon: React.FunctionComponent<SVGProps<SVGSVGElement>>;
  path: string;
  exact: boolean;
  isDisabled?: boolean;
}

export const ASIDE_MENU_LINKS: AsideMenuItem[] = [
  {
    title: 'Dashboard',
    icon: DashboardIcon,
    path: PAGES.DASHBOARD,
    exact: true,
    isDisabled: false
  },
  {
    title: 'Investments',
    icon: InvestmentsIcon,
    path: PAGES.INVESTMENTS,
    exact: false,
    isDisabled: false
  },
  {
    title: 'Opportunities',
    icon: () => <OpportunitiesIcon />,
    path: PAGES.OPPORTUNITIES,
    exact: false,
    isDisabled: false
  },
  {
    title: 'Portfolio Builder',
    icon: BuilderIcon,
    path: PAGES.PORTFOLIO_BUILDER,
    exact: false,
    isDisabled: false
  },
  {
    title: 'Statistics',
    icon: StatisticsIcon,
    path: PAGES.STATISTICS,
    exact: true,
    isDisabled: false
  },
  {
    title: 'Transactions',
    icon: () => <ReportsIcon />,
    path: PAGES.REPORTS,
    exact: true,
    isDisabled: false
  },
  {
    title: 'Marketplace',
    icon: MarketplaceIcon,
    path: PAGES.MARKETPLACE,
    exact: false,
    isDisabled: false
  }
];

export const ASIDE_MENU_OPERATIONS_LINKS: AsideMenuItem[] = [
  {
    title: 'Dashboard',
    icon: DashboardIcon,
    path: PAGES.DASHBOARD,
    exact: true,
    isDisabled: false
  },
  {
    title: 'Investments',
    icon: InvestmentsIcon,
    path: PAGES.INVESTMENTS,
    exact: false,
    isDisabled: false
  },
  {
    title: 'Statistics',
    icon: StatisticsIcon,
    path: PAGES.STATISTICS,
    exact: true,
    isDisabled: false
  }
];

export const ROUTE_OPTIONS = [
  {
    path: PAGES.HOME,
    component: HomePage,
    exact: true,
    isPrivate: false,
    redirectTo: null
  },
  {
    path: PAGES.UPDATE_PASSWORD,
    component: UpdatePasswordPage,
    exact: true,
    isPrivate: false
  },
  {
    path: PAGES.DASHBOARD,
    component: DashboardPage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.SIGN_IN
  },
  {
    path: PAGES.INVESTMENTS,
    component: InvestmentsPage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.SIGN_IN
  },
  {
    path: PAGES.OUTSTANDING_BIDS,
    component: InvestmentsPage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.SIGN_IN
  },
  {
    path: PAGES.OPPORTUNITIES,
    component: OpportunitiesPage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.SIGN_IN
  },

  {
    path: PAGES.MARKETPLACE_CONSUMER_DETAILS,
    component: EntityDetailsPage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.SIGN_IN
  },
  {
    path: PAGES.MARKETPLACE_SME_DETAILS,
    component: EntityDetailsPage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.SIGN_IN
  },
  {
    path: PAGES.OPPORTUNITY_DETAILS,
    component: EntityDetailsPage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.SIGN_IN
  },
  {
    path: PAGES.INVESTMENT_DETAILS,
    component: EntityDetailsPage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.SIGN_IN
  },
  {
    path: PAGES.BID_DETAILS,
    component: EntityDetailsPage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.SIGN_IN
  },

  {
    path: PAGES.PORTFOLIO_BUILDER,
    component: PortfolioBuilderPage,
    exact: false,
    isPrivate: true,
    redirectTo: PAGES.SIGN_IN
  },
  {
    path: PAGES.PLATFORM_ANALYTICS,
    component: PlatformAnalyticsPage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.SIGN_IN
  },
  {
    path: PAGES.STATISTICS,
    component: StatisticsPage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.SIGN_IN
  },
  {
    path: PAGES.REPORTS,
    component: ReportsPage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.SIGN_IN
  },
  {
    path: PAGES.MARKETPLACE,
    component: MarketplaceGlobal,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.SIGN_IN
  },
  {
    path: PAGES.MARKETPLACE_CONSUMER,
    component: MarketplacePage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.SIGN_IN
  },
  {
    path: PAGES.MARKETPLACE_SME,
    component: MarketplacePage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.SIGN_IN
  },
  {
    path: PAGES.HELP_CENTER,
    component: HelpCenterPage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.SIGN_IN
  },
  {
    path: PAGES.MY_PROFILE,
    component: MyProfilePage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.SIGN_IN
  },
  {
    path: PAGES.SETTINGS_AND_PRIVACY,
    component: SettingsAndPrivacyPage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.SIGN_IN
  },
  {
    path: PAGES.API_SETTINGS,
    component: ApiSettingsPage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.SIGN_IN
  },
  {
    path: PAGES.SERVER_ERROR,
    component: ServerErrorPage,
    exact: true,
    isPrivate: false
  },
  {
    path: PAGES.SIGN_IN,
    component: LoginPage,
    exact: true,
    isPrivate: false
  },
  {
    path: PAGES.SIGN_UP,
    component: RegisterPage,
    exact: true,
    isPrivate: false
  },
  {
    path: PAGES.PASSWORD_RECOVERY,
    component: PasswordRecoveryPage,
    exact: true,
    isPrivate: false
  },
  {
    path: PAGES.CONFIRM_PASSWORD_RECOVERY,
    component: ConfirmPasswordRecoveryPage,
    exact: true,
    isPrivate: false
  },
  {
    path: PAGES.CHANGE_PASSWORD_SUCCESS,
    component: ChangePasswordSuccessPage,
    exact: true,
    isPrivate: false
  },
  {
    path: PAGES.ACCOUNT_VERIFIED,
    component: AccountVerifiedPage,
    exact: true,
    isPrivate: false
  },
  {
    path: PAGES.API_SETTINGS,
    component: ApiSettingsPage,
    exact: true,
    isPrivate: true
  },
  {
    path: PAGES.ERROR_404,
    component: Error404Page,
    exact: false,
    isPrivate: false
  }
];
