import React, { FC } from 'react';
import NumberFormat from 'react-number-format';
import cn from 'classnames';
import { Fade } from '@mui/material';
import { Typography } from 'src/components';
import { MarketplaceLoan, InvestmentHistory } from 'src/interfaces';
import { Purposes } from 'src/constants/statisticsConstants';
import { INVESTMENT_HISTORY_LABELS } from 'src/constants/investmentsConstants';
import { formatDateFromJSON } from 'src/helpers';
import styles from './styles.module.scss';

const DATE_FORMAT = 'dd MMM yyyy';

type ContentProps = {
  entity: MarketplaceLoan;
  currency: string;
};

export const LoanBlockLeft: FC<ContentProps> = ({ entity, currency }) => (
  <Box>
    <DetailsRow label="Loan Originator" value={entity.source_platform_name?.toLowerCase() ?? '-'} capitalizeValue />
    <DetailsRow
      label="Listing Date"
      value={entity.origination_date ? formatDateFromJSON(entity.origination_date, DATE_FORMAT) : '-'}
    />
    <DetailsRow
      label="Loan Amount"
      value={entity.loan_amount ? entity.loan_amount.toFixed(2) : '-'}
      valuePrefix={`${currency} `}
      isNumericValue
    />
    <DetailsRow
      label="Interest rate"
      value={entity.borrower_rate ? (entity.borrower_rate * 100).toFixed(2) + '%' : '-'}
    />
    <DetailsRow label="Exaloan Rate" value={entity.grade ?? '-'} />
    <DetailsRow label="Relative Value" value={entity.relative_value ?? '-'} />
    <DetailsRow label="Term" value={entity.term_original ?? '-'} />
  </Box>
);

export const ConsumerBlockRight: FC<ContentProps> = ({ entity, currency }) => (
  <Box>
    <DetailsRow label="Borrower Age" value={entity.borrower_age ?? '-'} />
    <DetailsRow
      label="Borrower occupation"
      value={entity.borrower_occupation ? entity.borrower_occupation.toLowerCase() : '-'}
      capitalizeValue
    />
    <DetailsRow
      label="Employment Status"
      value={entity.employment_status ? entity.employment_status?.replaceAll('_', ' ') : '-'}
      capitalizeValue
    />
    <DetailsRow label="Loan Purpose" value={entity.loan_purpose ? Purposes[entity.loan_purpose] : '-'} />
    <DetailsRow
      label="Maturity Date"
      value={entity.maturity_date ? formatDateFromJSON(entity.maturity_date, DATE_FORMAT) : '-'}
    />
    <DetailsRow
      label="Total Income Monthly"
      value={entity.total_income_monthly ? (+entity.total_income_monthly)?.toFixed(2) : '-'}
      valuePrefix={`${currency} `}
      isNumericValue
    />
  </Box>
);

export const SmeBlockRight: FC<ContentProps> = ({ entity, currency }) => (
  <Box>
    <DetailsRow label="NACE Sector" value={entity.sector ?? '-'} />
    <DetailsRow label="Loan Purpose" value={entity.loan_purpose ? Purposes[entity.loan_purpose] : '-'} />
    <DetailsRow
      label="Total Assets"
      value={entity.total_assets ? (+entity.total_assets).toFixed(2) : '-'}
      valuePrefix={`${currency} `}
      isNumericValue
    />
    <DetailsRow
      label="Revenue"
      value={entity.revenue ? entity.revenue.toFixed() : '-'}
      valuePrefix={`${currency} `}
      isNumericValue
    />
    <DetailsRow
      label="EBITDA"
      value={entity.EBITDA ? (+entity.EBITDA)?.toFixed(2) : '-'}
      valuePrefix={`${currency} `}
      isNumericValue
    />
    <DetailsRow
      label="Maturity Date"
      value={entity.maturity_date ? formatDateFromJSON(entity.maturity_date, DATE_FORMAT) : '-'}
    />
  </Box>
);

type BoxProps = {
  children: React.ReactNode;
  style?: Record<string, string | number>;
};

export const Box: FC<BoxProps> = ({ children, style }) => (
  <div className={styles.contentBox} style={style}>
    {children}
  </div>
);

type DetailsRowProps = {
  label: string;
  value: string | number;
  valuePrefix?: string;
  valueSuffix?: string;
  isNumericValue?: boolean;
  capitalizeValue?: boolean;
  detailsLabelClass?: string;
};

export const DetailsRow: FC<DetailsRowProps> = ({
  label,
  value,
  isNumericValue,
  capitalizeValue,
  valuePrefix,
  valueSuffix,
  detailsLabelClass
}) => (
  <div className={styles.detailsRow}>
    <Typography component="h3" className={cn(styles.detailsLabel, { [detailsLabelClass]: detailsLabelClass })}>
      {label}:
    </Typography>

    {isNumericValue ? (
      <NumberFormat
        displayType="text"
        thousandSeparator
        value={value}
        prefix={valuePrefix}
        suffix={valueSuffix}
        className={cn({ [styles.capitalizeValue]: capitalizeValue })}
      />
    ) : (
      <Typography component="span" className={cn({ [styles.capitalizeValue]: capitalizeValue })}>
        {value}
      </Typography>
    )}
  </div>
);

type InvestmentsStatusProps = {
  isBidPage: boolean;
  status: string;
};

export const InvestmentsStatusBlock: FC<InvestmentsStatusProps> = ({ isBidPage, status }) => {
  return (
    <Box>
      <DetailsRow label={isBidPage ? 'Bid Status' : 'Investment Status'} value={status} capitalizeValue />
    </Box>
  );
};

type InvestmentAmountProps = {
  investmentAmount: number;
  currency: string;
};

export const InvestmentAmountBlock: FC<InvestmentAmountProps> = ({ investmentAmount, currency }) => {
  return (
    <Box>
      <DetailsRow
        label="Investment Amount"
        value={investmentAmount?.toFixed(2)}
        valuePrefix={`${currency} `}
        isNumericValue
      />
    </Box>
  );
};

type HistoryBlockProps = {
  isLoading: boolean;
  historyData: InvestmentHistory[];
};

const HISTORY_DEFAULT_HEIGHT = 51;
const HISTORY_ELEM_HEIGHT = 23;

export const HistoryBlock: FC<HistoryBlockProps> = ({ isLoading, historyData }) => {
  return (
    <Box style={{ minHeight: HISTORY_DEFAULT_HEIGHT + historyData.length * HISTORY_ELEM_HEIGHT }}>
      <Fade in={!isLoading}>
        <div className={styles.historyContent}>
          <h3 className={styles.historyTitle}>History:</h3>

          {historyData.map((h) => (
            <HistoryDataRow key={h.date} {...h} />
          ))}
        </div>
      </Fade>
    </Box>
  );
};

const HISTORY_DATE_FORMAT = 'd MMM yyyy HH:mm:ss';

const HistoryDataRow: FC<InvestmentHistory> = ({ date, status }) => {
  return (
    <div className={styles.historyDataBox}>
      <Typography component="span">{formatDateFromJSON(date, HISTORY_DATE_FORMAT) + ' - '}</Typography>
      <Typography component="span">{INVESTMENT_HISTORY_LABELS[status]}</Typography>
    </div>
  );
};
