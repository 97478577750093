import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Fade } from '@mui/material';
import { MainLayout, ButtonUnderlined as ButtonBack, ShouldRender } from 'src/components';
import { getEntityDetails } from 'src/redux/actions/investmentsActions';
import { LoanTypes } from 'src/constants/globalConstants';
import { INVESTMENT_HISTORY_LABELS } from 'src/constants/investmentsConstants';
import { currencySelector } from 'src/redux/selectors';
import { useEntityDetailsPathParams, useInvestmentHistory, useInvestmentRepayments } from './hooks';
import {
  LoanBlockLeft,
  ConsumerBlockRight,
  SmeBlockRight,
  HistoryBlock,
  InvestmentsStatusBlock,
  InvestmentAmountBlock,
  DetailsRow
} from './components';
import { Cashflow } from './components/Cashflow';
import { investmentDataHandler, outstandingBidDataHandler } from './helpers';
import { IEntityDetails } from './model';
import styles from './EntityDetailsPage.module.scss';

const EntityDetailsPage: FC = () => {
  const dispatch = useDispatch();
  const currency = useSelector(currencySelector);

  const { id, isLoan, path, label, loanIdLabelType, pathname, push, isBidsPage, isInvestmentsContent } =
    useEntityDetailsPathParams();

  const [entity, setEntity] = useState<IEntityDetails>(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    dispatch(
      getEntityDetails({
        id,
        isLoan,
        currency: currency.value,
        onSuccess: (data) => {
          if (isLoan) setEntity(data);
          if (!isLoan && !isBidsPage) setEntity(investmentDataHandler(data));
          if (!isLoan && isBidsPage) setEntity(outstandingBidDataHandler(data));

          setLoading(false);
        },
        onError: () => setLoading(false)
      })
    );
  }, [dispatch, id, isLoan, isBidsPage, currency]);

  const [isHistoryLoading, historyData, shouldHideHistory] = useInvestmentHistory(dispatch, pathname, id);

  const [isRepaymentsLoading, cashflowData, shouldShowRepayments] = useInvestmentRepayments(
    dispatch,
    pathname,
    entity,
    currency.value
  );

  const isSMEType = useMemo(
    () => (entity?.loanType ? entity?.loanType === LoanTypes.SME : entity?.type === LoanTypes.SME),
    [entity]
  );

  return (
    <MainLayout isEmptySkeleton>
      <div className={styles.content}>
        <div className={styles.headBlock}>
          <ButtonBack prefix="< " classes={{ btnClass: styles.buttonBack }} onClick={() => push(path)} label={label} />

          <Fade in={!isLoading && !!entity?.uniq}>
            <div>
              <DetailsRow label={loanIdLabelType} value={entity?.uniq} detailsLabelClass={styles.uniqIdLabelClass} />
            </div>
          </Fade>
        </div>

        <Fade in={!isLoading && !!entity} unmountOnExit>
          <div className={styles.detailsContent}>
            <div className={styles.detailsBlock}>
              <div className={styles.column}>
                <LoanBlockLeft entity={entity} currency={currency.value} />

                <ShouldRender should={isInvestmentsContent}>
                  <InvestmentAmountBlock investmentAmount={entity?.invested_amount} currency={currency.value} />
                </ShouldRender>
              </div>

              <div className={styles.column}>
                {isSMEType ? (
                  <SmeBlockRight entity={entity} currency={currency.value} />
                ) : (
                  <ConsumerBlockRight entity={entity} currency={currency.value} />
                )}

                <ShouldRender should={isInvestmentsContent}>
                  <InvestmentsStatusBlock
                    isBidPage={isBidsPage}
                    status={isBidsPage ? INVESTMENT_HISTORY_LABELS[entity?.bid_statuses] : entity?.loan_status}
                  />
                </ShouldRender>

                <ShouldRender should={!shouldHideHistory && !!historyData.length}>
                  <HistoryBlock isLoading={isHistoryLoading} historyData={historyData} />
                </ShouldRender>
              </div>
            </div>

            <ShouldRender should={shouldShowRepayments}>
              <Cashflow isLoading={isRepaymentsLoading} cashflowData={cashflowData} currency={currency.value} />
            </ShouldRender>
          </div>
        </Fade>
      </div>
    </MainLayout>
  );
};

export default EntityDetailsPage;
