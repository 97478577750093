import React, { FC, useState, useEffect, useMemo, useRef, createRef, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { FadeTransition } from 'src/components';
import { Formik } from 'formik';
import some from 'lodash/some';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { MainLayout, FadeWrapper } from 'src/components';
import { PAGES, DYNAMIC_PAGES } from 'src/constants/pages';
import { LoanTypes, PlatformsIDs } from 'src/constants/globalConstants';
import { AllocationPayload } from 'src/interfaces';
import { digitsRegex } from 'src/helpers/regexHelpers';
import { ConfirmBlock, TypeBlock, PlatformsBlock, CriteriaManagePanel, CriteriaFilters, NameBlock } from './components';
import {
  getAllocationTitle,
  mapAllocationTypeView,
  getInitialTypeValues,
  validationSchema,
  validateTypeBlock,
  getRelatedCriteriaItem,
  getExtendedCriteriaFilters,
  getAllocationPayload,
  getPlatformDetailsByCriteriaFilters,
  getGeneralCriteriaHasErrors,
  setExtendedCriteriaToPlatform
} from './helpers';
import {
  useAllocationDetails,
  useAutoApproval,
  useCriteriaFilters,
  usePlatforms,
  useChangePlatformsLoanType,
  useResetPurposeValueOnTypeChange
} from './hooks';
import { TypeBlockValues, Platform, RangeFiltersTypes } from './model';
import { ALLOCATION_ACTIVE_STATUS } from './constants';
import styles from './AllocationPage.module.scss';

const mainLayoutClasses = {
  privateContentClass: styles.privateContentClass,
  contentPaddingClass: styles.contentPaddingClass,
  privateInnerClass: styles.privateInnerClass
};

const platformsTransition = Object.values(PlatformsIDs).map((id) => ({ id, nodeRef: createRef<HTMLDivElement>() }));

type AllocationPageProps = {
  isViewPage?: boolean;
  isEditPage?: boolean;
  onConfirmAllocation?: (values: AllocationPayload, id?: string) => void;
};

const AllocationPage: FC<AllocationPageProps> = ({ isViewPage, isEditPage, onConfirmAllocation }): JSX.Element => {
  const params = useParams<{ id?: string }>();
  const { push } = useHistory();
  const allocationDetails = useAllocationDetails();

  const [autoApproval, setAutoApproval] = useAutoApproval(allocationDetails);

  const isNewMode = !isViewPage && !isEditPage;

  const [allocationType, setAllocationType] = useState<LoanTypes>(null);

  const criteriaFilters = useCriteriaFilters(allocationType, setAllocationType, allocationDetails, isNewMode);

  const { platformNames, serverPlatforms } = usePlatforms(allocationType);

  const isInit = useRef(false);
  const [investedPlatforms, setInvestedPlatforms] = useState<Platform[]>([]);

  useChangePlatformsLoanType(platformNames, setInvestedPlatforms, isNewMode);
  useResetPurposeValueOnTypeChange(allocationType, setInvestedPlatforms, isNewMode);

  useEffect(() => {
    if (!params.id || some(criteriaFilters, isEmpty) || !allocationDetails || isInit.current) return;

    setInvestedPlatforms(getPlatformDetailsByCriteriaFilters(allocationDetails, criteriaFilters));
    isInit.current = true;
  }, [criteriaFilters, params, allocationDetails]);

  const [selectedID, setSelectedID] = useState<PlatformsIDs>(null);

  const onApplyManageCriteriaChange = useCallback(
    (id: PlatformsIDs, criteriaSelected: string[]) => {
      const criteriaExtended = getExtendedCriteriaFilters(criteriaFilters);

      setInvestedPlatforms((prev) =>
        prev.map((platform) => {
          const platformCurrency = serverPlatforms.find(({ name }) => name === platform.id)?.currency;

          return platform.id === id
            ? setExtendedCriteriaToPlatform(platform, criteriaSelected, criteriaExtended, platformCurrency)
            : platform;
        })
      );
    },
    [criteriaFilters, serverPlatforms]
  );

  const onInvestmentChange = (id: PlatformsIDs, investmentPercentage: number) => {
    setInvestedPlatforms((prev) => prev.map((item) => (item.id === id ? { ...item, investmentPercentage } : item)));
  };

  const onGeneralRangeChange = (id: PlatformsIDs, rangeID: RangeFiltersTypes, value: string[] | number[]) => {
    const [min, max] = value;
    setInvestedPlatforms((prev) =>
      prev.map((item) => (item.id === id ? { ...item, general: { ...item.general, [rangeID]: { min, max } } } : item))
    );
  };

  const onCriteriaChange = useCallback(
    (criteriaName: string, id: string | number, percentage: number) => {
      setInvestedPlatforms((prev) =>
        prev.map((item) =>
          item.id === selectedID
            ? { ...item, [criteriaName]: getRelatedCriteriaItem(criteriaName, item[criteriaName], id, percentage) }
            : item
        )
      );
    },
    [selectedID]
  );

  const handleViewToEdit = () => push(DYNAMIC_PAGES.EDIT_ALLOCATION(params?.id));

  const selectedPlatform = useMemo(
    () => (selectedID ? investedPlatforms.find(({ id }) => id === selectedID) : null),
    [investedPlatforms, selectedID]
  );

  const allocationTypeData = useMemo(() => mapAllocationTypeView(allocationDetails), [allocationDetails]);
  const initialValues = useMemo(() => getInitialTypeValues(allocationTypeData), [allocationTypeData]);

  const transitionRef = useMemo(
    () => (selectedID ? platformsTransition.find(({ id }) => id === selectedID)?.nodeRef : null),
    [selectedID]
  );

  const hasGeneralCriteriaErrors = useMemo(() => getGeneralCriteriaHasErrors(investedPlatforms), [investedPlatforms]);
  const hasSelectedCriteria = !isEmpty(selectedPlatform?.criteriaSelected);
  const hasSelectedPlatforms = !isEmpty(investedPlatforms);
  const disableConfirmViewCase = !allocationDetails || allocationDetails?.status === ALLOCATION_ACTIVE_STATUS;

  return (
    <MainLayout isPrivate isEmptySkeleton classes={mainLayoutClasses}>
      <div className={styles.content}>
        <Formik
          enableReinitialize={isViewPage || isEditPage}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validate={(values: TypeBlockValues) => validateTypeBlock(values, allocationTypeData?.name)}
          onSubmit={({ name, ...values }: TypeBlockValues) => {
            const payload = getAllocationPayload({ ...values, name: name.trim() }, investedPlatforms, autoApproval);

            onConfirmAllocation(payload, params?.id);
          }}
        >
          {({ values, errors, touched, handleSubmit, setErrors }) => {
            const hasErrors = some(errors, (value) => !!value);
            const haveNotTouched = isEmpty(touched);
            const clearValues = omit(values, ['floatValue', 'maxBidSize', 'structureEndDate']);

            const isEditAndEqualWithInitial = isEditPage && !!allocationDetails && isEqual(initialValues, clearValues);

            const shouldClearErrors = isEditAndEqualWithInitial && hasErrors && haveNotTouched;
            if (shouldClearErrors) setErrors({});

            const hasFalsyValues = some(clearValues, (value) => !value);

            const isConfirmDisabled = hasFalsyValues || hasErrors || !hasSelectedPlatforms || hasGeneralCriteriaErrors;

            const showPlatforms = !!+values.amount.replace(digitsRegex, '') && !!values.loanType?.value;
            const isSME = values?.loanType?.value === LoanTypes.SME;

            return (
              <React.Fragment>
                <ConfirmBlock
                  isViewPage={isViewPage}
                  title={getAllocationTitle(isViewPage, isEditPage)}
                  backButtonLabel="Back To Portfolio List"
                  onBackButtonClick={() => push(PAGES.PORTFOLIO_BUILDER)}
                  hideConfirmBtn={false}
                  confirmBtnLabel={isViewPage ? 'Edit' : 'Confirm'}
                  isConfirmDisabled={isViewPage ? disableConfirmViewCase : isConfirmDisabled}
                  onConfirm={isViewPage ? handleViewToEdit : handleSubmit}
                  wrapperClass={styles.confirmBlock}
                />

                <NameBlock isViewMode={isViewPage} name={values?.name} />

                <TypeBlock
                  isViewMode={isViewPage}
                  isEditPage={isEditPage}
                  isAutoApproval={autoApproval}
                  onToggleAuthApproval={() => setAutoApproval((p) => !p)}
                  onSetAllocationType={setAllocationType}
                  wrapperClass={styles.typeBlock}
                />

                <FadeWrapper show={showPlatforms} unmountOnExit>
                  <PlatformsBlock
                    isViewMode={isViewPage}
                    investedPlatforms={investedPlatforms}
                    onSetInvestedPlatforms={setInvestedPlatforms}
                    allocationAmount={+values.amount.replace(digitsRegex, '')}
                    serverPlatforms={serverPlatforms}
                    selectedCurrency={values.currency.value}
                    selectedID={selectedID}
                    onSetSelectedID={setSelectedID}
                    wrapperClass={styles.platformsBlock}
                  />
                </FadeWrapper>

                <FadeWrapper show={!isViewPage && showPlatforms && hasSelectedPlatforms} unmountOnExit>
                  <CriteriaManagePanel
                    isSME={isSME}
                    isViewPage={isViewPage}
                    selectedID={selectedID}
                    selectedPlatform={selectedPlatform}
                    onApplyCriterias={onApplyManageCriteriaChange}
                    onSetInvestedPlatforms={setInvestedPlatforms}
                    wrapperClass={styles.criteriaManage}
                  />
                </FadeWrapper>

                <FadeWrapper show={showPlatforms && selectedPlatform && hasSelectedCriteria} unmountOnExit>
                  <SwitchTransition>
                    <CSSTransition
                      key={selectedID}
                      nodeRef={transitionRef}
                      addEndListener={(done) => {
                        transitionRef.current.addEventListener('transitionend', done, false);
                      }}
                      classNames={{ ...FadeTransition } as unknown}
                    >
                      <div ref={transitionRef}>
                        <CriteriaFilters
                          isSME={isSME}
                          isViewPage={isViewPage}
                          selectedPlatform={selectedPlatform}
                          onInvestmentChange={(value: number) => onInvestmentChange(selectedID, value)}
                          investmentCurrency={values.currency.value}
                          onGeneralRangeChange={(rangeID, values: number[]) => {
                            onGeneralRangeChange(selectedID, rangeID, values);
                          }}
                          onCriteriaChange={onCriteriaChange}
                        />
                      </div>
                    </CSSTransition>
                  </SwitchTransition>
                </FadeWrapper>
              </React.Fragment>
            );
          }}
        </Formik>
      </div>
    </MainLayout>
  );
};

export default AllocationPage;
